import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { UserQueryKeys } from '@enums/react-query-keys';
import { Product } from '@interfaces/models/product';
import ProductService from '@services/product-service';
import { getProductsLikedByCurrentlyAuthenticatedUser } from '@api/authenticated-user';
import { UserLikes } from '@interfaces/models/userLikes';
import useUser from '@hooks/user/use-user';
import { useEffect } from 'react';

const LOCAL_STORAGE_KEY = 'userLikes';

const useUserLikedProductsQuery = () => {
  const { user, isAuthenticated } = useUser();
  const queryClient = useQueryClient();

  const { data: likedProducts } = useQuery<Product['id'][]>({
    queryKey: [UserQueryKeys.LIKED_PRODUCTS, user?.id],
    queryFn: async (): Promise<Product['id'][]> => {
      const likedItemsInLocalStorage = localStorage.getItem(LOCAL_STORAGE_KEY);
      const likedProductsFromLocalStorage =
        likedItemsInLocalStorage !== null ? (JSON.parse(likedItemsInLocalStorage) as UserLikes)?.products : [];
      const likedProductsFromApi = await getProductsLikedByCurrentlyAuthenticatedUser();
      return Array.from(new Set([...likedProductsFromApi, ...likedProductsFromLocalStorage]));
    },
    enabled: isAuthenticated,
    initialData: [],
    // Cache for 30min, because there exists a ~ 30min queue between updates to liked products
    cacheTime: 30 * 60 * 1000,
  });

  const { mutate: likeProduct } = useMutation({
    mutationFn: (productId: Product['id']) => ProductService.likeProduct(productId),
    // Manually remove the product from the cached data, instead of invalidating the cache, because we have a server queue for
    // handling liking/unliking and re-fetching likes immediately would just yield previous results.
    onMutate: (productId: Product['id']) => {
      queryClient.setQueryData([UserQueryKeys.LIKED_PRODUCTS, user?.id], (prevData: Product['id'][]) => {
        // Creating a set here just to make sure we don't have any duplicates for whichever reason
        return Array.from(new Set([...prevData, productId]));
      });
      const likedProducts = queryClient.getQueryData([UserQueryKeys.LIKED_PRODUCTS, user?.id]);
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({ products: likedProducts }));
    },
  });

  const { mutate: unlikeProduct } = useMutation({
    mutationFn: (productId: Product['id']) => ProductService.unlikeProduct(productId),
    // Manually remove the product from the cached data, instead of invalidating the cache, because we have a server queue for
    // handling liking/unliking and re-fetching likes immediately would just yield previous results.
    onMutate: (productId: Product['id']) => {
      queryClient.setQueryData([UserQueryKeys.LIKED_PRODUCTS, user?.id], (prevData: Product['id'][]) => {
        return prevData.filter((cachedProductId: Product['id']) => productId !== cachedProductId);
      });
      const likedProducts = queryClient.getQueryData([UserQueryKeys.LIKED_PRODUCTS, user?.id]);
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({ products: likedProducts }));
    },
  });

  useEffect(() => {
    if (!isAuthenticated && localStorage?.getItem(LOCAL_STORAGE_KEY) !== null) {
      localStorage.removeItem(LOCAL_STORAGE_KEY);
    }
  }, []);

  return {
    likedProducts,
    likeProduct,
    unlikeProduct,
  };
};

export default useUserLikedProductsQuery;
