import { Negotiation } from '@interfaces/models/negotiation/negotiation';
import { createContext, Dispatch, FCWithChildren, SetStateAction, useContext, useState } from 'react';
import { NegotiationInitialData } from '@interfaces/models/negotiation/negotiationInitialData';

interface NegotiationContextProps {
  negotiation: Negotiation;
  setNegotiation: Dispatch<SetStateAction<Negotiation>>;
  negotiationList: Negotiation[];
  setNegotiationList: Dispatch<SetStateAction<Negotiation[]>>;
  setInitialData: Dispatch<SetStateAction<NegotiationInitialData>>;
  initialData: NegotiationInitialData;
}

export type NegotiationProviderProps = {
  negotiation?: Negotiation;
  initialData?: NegotiationInitialData;
};

const NegotiationStateContext = createContext<NegotiationContextProps>(undefined);

const NegotiationProvider: FCWithChildren<NegotiationProviderProps> = (props) => {
  const { negotiation: defaultNegotiation, initialData: defaultInitialData, children } = props;

  const [negotiation, setNegotiation] = useState<Negotiation>(defaultNegotiation);
  const [negotiationList, setNegotiationList] = useState<Negotiation[]>([]);
  const [initialData, setInitialData] = useState<NegotiationInitialData>(defaultInitialData);

  const value = {
    setInitialData,
    initialData,
    negotiation,
    setNegotiation,
    negotiationList,
    setNegotiationList,
  };
  return <NegotiationStateContext.Provider value={value}>{children}</NegotiationStateContext.Provider>;
};

const useNegotiation = () => useContext(NegotiationStateContext);

export { NegotiationProvider, useNegotiation };
