import { useQuery } from '@tanstack/react-query';
import { FeatureFlagsKeys } from '@enums/react-query-keys';

const useFeatureFlag = () => {
  const { data: isSemPDP } = useQuery<boolean>({
    queryKey: [FeatureFlagsKeys.SEM_LANDING_PAGE_RECOMMENDATION_BLADES],
    staleTime: Infinity,
  });

  return {
    isSemPDP: !!isSemPDP,
  };
};

export default useFeatureFlag;
