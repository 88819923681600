import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import styles from '@components/organisms/campaign/campaign.module.scss';
import { Autoplay, EffectCreative, EffectFade, Lazy, Navigation, Pagination } from 'swiper';
import clsx from 'clsx';
import Icon from '@components/atoms/icon/icon';
import useWindowSize from '@hooks/use-window-size';
import { CampaignBlock as ICampaignBlock, CategoryContainer } from '@interfaces/models/campaign';
import CampaignBlock from '@components/molecules/campaign-block/campaign-block';

export const CAMPAIGN_SWIPER_ID = 'HP_campaignSwiper';
const SLIDER_ANIMATION_DURATION = 4200;
const slidesCount = {
  single: 1,
  double: 2,
  multiple: 4,
};

// Pass animation duration to inner bullet animation
const sliderStyles = {
  '--vs-slider-animation-duration': `${SLIDER_ANIMATION_DURATION}ms`,
  '--swiper-navigation-size': '25px',
} as React.CSSProperties;

interface CampaignSwiperProps {
  componentID: string;
  categoryContainer: CategoryContainer;
  campaignBlocks: ICampaignBlock[];
  sectionIdx: string;
}

const CampaignSwiper = ({ campaignBlocks, componentID, categoryContainer, sectionIdx }: CampaignSwiperProps) => {
  const { isWiderThanMd } = useWindowSize();

  // NAVIGATION
  const navigationPrev = useRef<HTMLDivElement>(null);
  const navigationNext = useRef<HTMLDivElement>(null);

  return (
    <Swiper
      id={CAMPAIGN_SWIPER_ID}
      draggable
      loop={campaignBlocks.length >= 2}
      watchOverflow
      className={styles.slider}
      effect={isWiderThanMd ? 'fade' : null}
      modules={[Autoplay, EffectCreative, Pagination, Lazy, EffectFade, Navigation]}
      slidesPerView={1}
      style={sliderStyles}
      autoplay={{
        delay: SLIDER_ANIMATION_DURATION,
        disableOnInteraction: false,
      }}
      navigation={{
        prevEl: navigationPrev.current!,
        nextEl: navigationNext.current!,
      }}
      onBeforeInit={(swiper) => {
        //@ts-ignore
        swiper.params.navigation.prevEl = navigationPrev.current;
        //@ts-ignore
        swiper.params.navigation.nextEl = navigationNext.current;
        swiper.allowTouchMove = campaignBlocks.length > 1;
      }}
      pagination={{
        el: `#swiper_pagination-${componentID}`,
        clickable: true,
        bulletClass: clsx('swiper-pagination-bullet', styles.slider_pagination__bullet),
        bulletActiveClass: clsx('swiper-pagination-bullet-active', styles['slider_pagination__bullet--active']),
      }}
    >
      {campaignBlocks
        ?.slice(
          0,
          isWiderThanMd
            ? campaignBlocks.length === 1
              ? slidesCount.single
              : slidesCount.multiple
            : slidesCount.multiple,
        )
        ?.map((campaignBlock, idx) => {
          const { uid: UID, displayTitle: title } = campaignBlock || {};
          const key = `${title || ''}-${UID}`;
          return (
            <SwiperSlide key={key}>
              <CampaignBlock
                isHeroVersion={true}
                isSingle={campaignBlocks.length === 1}
                categoryContainer={categoryContainer}
                {...campaignBlock}
                sectionIdx={sectionIdx}
                posX={String(idx + 1)}
              />
            </SwiperSlide>
          );
        })}
      {campaignBlocks.length >= 2 && (
        <div className={clsx(styles.slider_button)}>
          <div
            ref={navigationPrev}
            className={clsx(styles.slider_button_prev)}
          >
            <Icon name="ios-arrow-left-16" />
          </div>
          <div
            ref={navigationNext}
            className={clsx(styles.slider_button_next)}
          >
            <Icon name="ios-arrow-right-16" />
          </div>
        </div>
      )}

      {campaignBlocks.length > 1 ? (
        <div
          className={styles.slider_pagination}
          id={`swiper_pagination-${componentID}`}
        />
      ) : null}
    </Swiper>
  );
};

export default CampaignSwiper;
