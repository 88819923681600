import React, { useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useContextSelector } from 'use-context-selector';
import Button from '@components/atoms/button/button';
import { CartContext } from '@context/cart.context';
import { ToastMessageContext } from '@context/toast-message.context';
import { useAnalytics } from '@context/analytics.context';
import { useBucket } from '@context/bucket.context';
import Spinner from '@components/atoms/spinner/spinner';
import logger from '@helpers/utils/logger/client';
import clsx from 'clsx';
import dynamic from 'next/dynamic';
import useUser from '@hooks/user/use-user';
import { useRouter } from 'next/router';
import { Product } from '@interfaces/models/product';
import useAnalyticEvents from '@hooks/analytics/use-analytic-events';
import Icon from '@components/atoms/icon/icon';
import { useNegotiation } from '@context/negotiation.context';
import { FavTileActionsABCtestVariations } from '@components/organisms/catalog/components/product-card/product-card';
import { NegotiationDialogStyles } from '@constants/negotiation';
import Link from '@components/atoms/link/link';
import Dialog from '@components/atoms/dialog/dialog';
import { useGetNegotiationById, useGetNegotiationInitialData } from '@hooks/negotiations/useNegotationQueries';
import styles from './product-card-fav-actions.module.scss';

const NegotiationModal = dynamic(
  () => import('@components/organisms/negotiation/negotiation-modal/negotiation-modal').then((x) => x.NegotiationModal),
  {
    ssr: false,
  },
);

interface ProductCardFavActionsProps {
  product: Product;
  favTileActionsABCtestVariations?: FavTileActionsABCtestVariations;
}

const ProductCardFavActions: React.FC<ProductCardFavActionsProps> = (props) => {
  const { product, favTileActionsABCtestVariations } = props;
  const { t } = useTranslation();
  const { user } = useUser();
  const router = useRouter();
  const [atcLoading, setAtcLoading] = useState<boolean>(false);
  const { isFeatureEnabled } = useBucket();
  const { sendEvent } = useAnalytics();
  const { sendAnalyticEvent } = useAnalyticEvents('favorites');
  const { negotiation } = useNegotiation();
  const cartUrl = useContextSelector(CartContext, (v) => v.cartUrl);
  const showToastMessage = useContextSelector(ToastMessageContext, (v) => v.showToastMessage);
  const toastMessageId = useContextSelector(ToastMessageContext, (v) => v.toastMessageId);
  const productsInCart = useContextSelector(CartContext, (v) => v.productsInCart);
  const [openNegotiationModal, setOpenNegotiationModal] = useState<boolean>(false);
  const isCartPreviewOpened = useContextSelector(CartContext, (v) => v.isCartPreviewOpened);
  const setIsCartPreviewOpened = useContextSelector(CartContext, (v) => v.setIsCartPreviewOpened);
  const isProductIdInCart = useContextSelector(CartContext, (v) => v.isProductIdInCart);
  const addToShoppingCart = useContextSelector(CartContext, (v) => v.addToShoppingCart);
  const hasFloatingCart = isFeatureEnabled((f) => !f.hideMiniCart, true);
  const { favoritesUserSlug, profileSlug, options } = router?.query as {
    favoritesUserSlug: string;
    options: string; // TODO: QA testing as the API doesn't return the correct payload
    profileSlug: string;
  };
  const favoritesUserSlugId = favoritesUserSlug?.[0] ?? profileSlug?.[0] ?? '';
  const isSellerOnVacation = options?.includes('sellerOnVacation') || !!product.seller.vacation;

  const isProductInCart = (product): boolean => {
    return isProductIdInCart(productsInCart, product.id);
  };

  const isUserFavoritesUserSlugId = user?.id === favoritesUserSlugId;
  const hasNegotiationId = !!product?.negotiationId;
  const variant1 = favTileActionsABCtestVariations === 'similar'; // see similar
  const variant2 = favTileActionsABCtestVariations === 'mmao'; // mmao / see offer

  const isProductSoldOrReserved =
    isUserFavoritesUserSlugId && (product?.sold || product?.reserved || user?.id === product?.seller?.id); // only see similar - no cart button
  const alreadyInCart = isProductInCart(product); // cart button with tick icon

  useGetNegotiationById({
    negotiationId: product.negotiationId,
    enabled: !!product.negotiationId,
  });

  useGetNegotiationInitialData({
    buyerId: null,
    productId: product.id,
    enabled: product && product.negotiable && !product.negotiationId && !isSellerOnVacation,
  });

  // TODO: Translate this label
  const mmaoOrSeeOfferLabel = !!negotiation
    ? t('FAVORITE_PAGE.PRODUCT_TILE.SEE_OFFER')
    : t('FAVORITE_PAGE.PRODUCT_TILE.MMAO'); // mmao or see offer label

  // TODO: Clean up after QA testing
  // TODO: Check negotiable property when the seller turn off the negotiation option for the product
  // TODO: Check if the seller is on vacation by setting the vacation mode
  // TODO: hasNegoationId is true when the product has an ongoing negotiation only when the user is the owner of the favorites page
  product.negotiable = options?.includes('isNegotiable')
    ? true
    : options?.includes('isNotNegotiable')
      ? false
      : product?.negotiable;
  const disableMakeAnOffer = (!product?.negotiable || isSellerOnVacation) && !hasNegotiationId;

  const getProductPayload = (product) => ({
    product_id: product.id,
    seller_id: product?.seller?.id,
    product_universe: product?.universe?.name,
    product_category: product?.category?.name,
    product_sub_category: product?.subcategory?.name,
    product_brand: product?.brand?.name,
    product_country: product?.seller?.country,
    product_unit_price: product?.price?.formatted,
    product_ds_eligible: product?.dsEligible,
    product_crossborder_transaction: `${product?.dutyAndTax?.cents > 0}`,
    product_currency: product?.price?.currency,
    product_sold: product?.sold,
  });

  const handleAlreadyInCartAction = (): void => {
    if (hasFloatingCart) {
      setIsCartPreviewOpened(!isCartPreviewOpened);
    } else {
      window.location.assign(cartUrl);
    }
    sendEvent({
      type: 'event',
      payload: {
        event: 'productCardAction',
        action: 'addToCart',
        product_id: product.id,
      },
    });
  };

  const addToCart = async (): Promise<void> => {
    setAtcLoading(true);
    try {
      await addToShoppingCart(product, false);
      sendAnalyticEvent('favorites_add_to_bag', {
        ...getProductPayload(product),
      });
    } catch (e) {
      logger.error('Error adding to cart - favourite product card action');
      if (showToastMessage && toastMessageId < 3) {
        showToastMessage(t('FAVORITE_PAGE.ADD_TO_CART.ERROR'), '', 3000);
      }
    } finally {
      setAtcLoading(false);
    }
  };

  const handleCartButtonClick = (): void => {
    if (isProductInCart(product)) {
      if (isCartPreviewOpened) {
        setIsCartPreviewOpened(!isCartPreviewOpened);
      } else {
        handleAlreadyInCartAction();
      }

      sendAnalyticEvent('favorites_add_to_bag_cta', {
        label: 'view_bag',
        ...getProductPayload(product),
      });
    } else if (!atcLoading) {
      sendAnalyticEvent('favorites_add_to_bag_cta', {
        label: 'add_to_bag_cta',
        ...getProductPayload(product),
      });
      addToCart();
    }
  };

  /**
   * Handle see similar click
   * Tracking needs to know if the user is coming from the favorites page to see similar products
   */
  const handleSeeSimilarClick = (): void => {
    sendAnalyticEvent('favorites_click_cta', {
      action: 'see_similar',
      ...getProductPayload(product),
    });
  };

  const handleMMAOSeeOfferButtonClick = (): void => {
    sendAnalyticEvent('favorites_click_cta', {
      action: hasNegotiationId ? 'see_offer' : 'make_an_offer',
      ...getProductPayload(product),
    });

    setOpenNegotiationModal(true);
  };

  /**
   * Cart button disabled / enabled based on the seller vacation status
   * Add to bag or tick icon based on the product already in cart or not
   * Show atcLoading spinner when adding to cart
   * */
  const renderAddToCartButton = () => (
    <Button
      aria-haspopup="true"
      aria-expanded={isCartPreviewOpened}
      onClick={handleCartButtonClick}
      data-cy={
        atcLoading
          ? 'favorite_product_tile_atc_loading'
          : atcLoading || (!alreadyInCart && isSellerOnVacation)
            ? 'favorite_product_tile_bag_disabled'
            : alreadyInCart
              ? 'favorite_product_tile_tick'
              : 'favorite_product_tile_bag'
      }
      variant={alreadyInCart && !atcLoading ? 'primary' : 'secondary'}
      size="small"
      className={clsx(
        atcLoading
          ? styles.productCardFavActions__cart__atcLoading
          : alreadyInCart && !atcLoading
            ? styles.productCardFavActions__cart__filled
            : styles.productCardFavActions__cart,
      )}
      disabled={atcLoading || (!alreadyInCart && isSellerOnVacation)}
    >
      {atcLoading ? (
        <div className={styles.productCardFavActions__cart__loadingContainer}>
          <Spinner className={styles.productCardFavActions__cart__loadingContainer__icon} />
        </div>
      ) : (
        <Icon
          className={clsx(styles.productCardFavActions__cart__icon)}
          name={alreadyInCart ? 'tick-small-default' : 'cart-default'}
          width={24}
          height={24}
        />
      )}
    </Button>
  );

  /**
   * Variant 2: Make an offer or See offer button and Add to cart button
   * Render Make an offer or See offer button based on the ongoing negotiation
   * Disable MMAO button if the product is not negotiable or the seller is on vacation and has ongoing negotiation
   */
  const renderMmaoOrSeeOfferButton = () => (
    <Button
      variant="secondary"
      size="medium"
      onClick={handleMMAOSeeOfferButtonClick}
      className={clsx(styles.productCardFavActions__mmao, 'vc-btn--compact')}
      data-cy={`favorite_product_tile_${
        hasNegotiationId ? 'see_offer' : disableMakeAnOffer ? 'mmao_disabled' : 'mmao'
      }`}
      disabled={disableMakeAnOffer}
    >
      <span>{mmaoOrSeeOfferLabel}</span>
    </Button>
  );

  /**
   * Variant 1: See similar and Add to cart button
   * Render only See similar button when the product is sold or reserved
   * Render See similar and Add to cart button when the user is the owner of the favorites page and the product is not sold or reserved
   * Render See similar and Add to cart button disabled when the seller is on vacation
   * Seeing other user's favorite products, render only See similar
   */
  const renderSeeSimilarButton = () => (
    <Link
      href={`/similar-products/?productId=${product?.id}&isFromFavTile=true`}
      onClick={handleSeeSimilarClick}
      data-cy="favorite_product_tile_see_similar"
      className={clsx(
        styles.productCardFavActions__similar,
        'vc-btn',
        'vc-btn--compact',
        'vc-btn--rounded-secondary',
        'vc-btn--medium',
      )}
    >
      {t('FAVORITE_PAGE.PRODUCT_TILE.SEE_SIMILAR')}
    </Link>
  );

  const renderCTAButtons = () => {
    if (isProductSoldOrReserved && (variant1 || variant2)) {
      return renderSeeSimilarButton();
    } else if (isUserFavoritesUserSlugId) {
      if (variant1) {
        // See Similar
        return (
          <>
            {renderSeeSimilarButton()}
            {renderAddToCartButton()}
          </>
        );
      } else if (variant2) {
        // MMAO / See offer
        return (
          <>
            {renderMmaoOrSeeOfferButton()}
            {renderAddToCartButton()}
          </>
        );
      }
    } else {
      // product is not sold, not reserved
      if (isUserFavoritesUserSlugId) {
        return renderSeeSimilarButton();
      }
    }

    return null;
  };

  const renderMmaoModal = () => {
    return (
      <Dialog
        closeDialog={() => {
          setOpenNegotiationModal(false);
        }}
        key={`openNegotiationModal${openNegotiationModal}`}
        isOpen={openNegotiationModal}
        hasCloseIcon
        styles={{
          content: NegotiationDialogStyles,
        }}
      >
        <NegotiationModal
          trackingFrom="mmao_ongoing_banner"
          showCheckoutModal={false}
        />
      </Dialog>
    );
  };

  return (
    <div className={styles.productCardFavActions}>
      {renderCTAButtons()}
      {renderMmaoModal()}
    </div>
  );
};

export default ProductCardFavActions;
