import { ApiResponse } from '@interfaces/api';
import {
  CatalogManagementData,
  CatalogManagementFilters,
  CatalogManagementProduct,
  CatalogManagementProductPrice,
} from '@interfaces/models/sell-catalog';
import {
  FetchPriceZoneParams,
  PriceDropPriceZoneResponse,
  RecommendationsPriceResponse,
} from '@interfaces/models/price-drop';
import Environment from '@config/index';
import { axios, getAxiosConfig, ServerAxiosRequestData } from '@config/axios';
import logger from '@helpers/utils/logger/client';

export default class SellCatalogService {
  private static baseUrl = Environment.apiBaseUrl;

  static async listingCatalogManagement(queryParamsString): Promise<ApiResponse<CatalogManagementData>> {
    const axiosConfig = {
      ...getAxiosConfig(),
      timeout: 20000,
    };
    const url = `${this.baseUrl}/catalog-management/products/list?${queryParamsString}`;
    const response = await axios.get(url, axiosConfig);
    return response?.data;
  }

  static async singleProductCatalogManagement(
    productId: CatalogManagementProduct['id'],
    serverAxiosRequestData?: ServerAxiosRequestData,
  ): Promise<CatalogManagementProduct> {
    const axiosConfig = getAxiosConfig(serverAxiosRequestData);
    const url = `${this.baseUrl}/catalog-management/products/${productId}`;
    const response = await axios.get(url, axiosConfig);
    return response?.data?.data;
  }

  static async fetchPriceDropRecommendation(productId: string): Promise<ApiResponse<RecommendationsPriceResponse>> {
    const axiosConfig = getAxiosConfig();
    const url = `${this.baseUrl}/users/me/product/${productId}/price-drop`;
    const response = await axios.get(url, axiosConfig);
    return response?.data;
  }

  static async fetchPriceZone({
    userId,
    productId,
    recommendedPrice,
    sellerPrice,
  }: FetchPriceZoneParams): Promise<ApiResponse<PriceDropPriceZoneResponse>> {
    const axiosConfig = getAxiosConfig();
    const url = `${this.baseUrl}/price-recommendation/get-price-zone`;

    const headers = {
      'x-vc-language': axiosConfig.params['x-language'],
      'x-vc-siteid': axiosConfig.params['x-siteid'],
      'x-vc-currency': axiosConfig.params['x-currency'],
    };
    axiosConfig.headers = {
      ...axiosConfig.headers,
      ...headers,
    };
    axiosConfig.params = {
      productId,
      userId,
      recommendedPrice,
      listingPrice: sellerPrice,
    };

    const response = await axios.get(url, axiosConfig);
    return response?.data;
  }

  static async getPriceDetails(price, id): Promise<ApiResponse<CatalogManagementProductPrice>> {
    const axiosConfig = getAxiosConfig();
    const url = `${this.baseUrl}/price-details?pvp=${price}&id_produit=${id}`;
    const response = await axios.get(url, axiosConfig);
    return response?.data;
  }

  static async dropPrice(price, id, userId: User['id']) {
    const axiosConfig = getAxiosConfig();
    const url = `${this.baseUrl}/users/me/products/price-drop`;
    const data = [
      {
        id: userId,
        type: 'priceDrop',
        price: price,
        productId: id,
      },
    ];
    const response = await axios.post(url, data, axiosConfig);
    return response?.data;
  }

  static async removeFromSale(id, reason) {
    const axiosConfig = getAxiosConfig();
    const url = `${this.baseUrl}/products/${id}/remove-from-sale?reasonId=${reason}`;
    const response = await axios.post(url, {}, axiosConfig);
    return response?.data;
  }

  static async getReasonsToCancelMySaleList(serverAxiosRequestData?: ServerAxiosRequestData) {
    const axiosConfig = getAxiosConfig(serverAxiosRequestData);
    const url = `${this.baseUrl}/products/unavailability-reasons`;
    const response = await axios.get(url, axiosConfig);
    return response?.data?.data;
  }

  static async submitCancelMySaleReason(id, payload) {
    const axiosConfig = getAxiosConfig();
    const url = `${this.baseUrl}/products/${id}/unavailability-reason`;
    const response = await axios.put(url, payload, axiosConfig);
    return response?.data;
  }

  static async getAllFilters(): Promise<CatalogManagementFilters[]> {
    const axiosConfig = getAxiosConfig();
    try {
      const url = `${this.baseUrl}/catalog-management/filters/list`;
      const {
        data: { data },
      } = await axios.get<ApiResponse<CatalogManagementFilters[]>>(url, axiosConfig);
      return data;
    } catch (err) {
      logger.error(err, 'Error while fetching catalog management filters. Server responded with : ');
      return;
    }
  }

  static async catalogExport() {
    const axiosConfig = getAxiosConfig();
    const url = `${this.baseUrl}/catalog-management/products/export`;
    const response = await axios.get(url, axiosConfig);
    return response?.data;
  }

  static async getBrands() {
    const axiosConfig = getAxiosConfig();
    const url = `${this.baseUrl}/deposit/brands`;
    const response = await axios.get(url, axiosConfig);
    return response?.data?.data;
  }
}
